<template>
  <div class="box">
        <img src="/images/activity/banner.png" class="cover-img" v-if="type=='sound'"/>
    <video
        v-else
        @canplay="isRead = true"
        @play="isPaused = false"
        @pause="isPaused = true"
        @loadedmetadata="loadedMetaData"
        @timeupdate="timeupdate"
        loop
        :poster="poster"
        ref="video"
        class="video"
        :src="data.video"
    ></video>
    <div class="progress-box">
      <div class="pro-top-box">
        <img src="/images/detail/story-icon.png" class="story-img">
        <p class="story-title">小镇故事</p>
      </div>
      <div>
        <div class="pro-bottom-box">
          <div class="cover-btn">
            <img
                @click="play"
                v-if="isPaused"
                class="btn-play"
                src="/images/detail/play.png"
                alt=""
            />
            <img
                v-show="!isPaused"
                @click="pause"
                class="btn-pause"
                src="@/assets/images/audio/pause.png"
                alt=""
            />
          </div>
          <span class="time-text">
             {{ fixed(currentTime) }}/{{ fixed(duration) }}
            </span>
<!--          <div @click="toggleVolume">-->
<!--            <img src="/images/detail/voice.png" class="voice-img">-->
<!--          </div>-->
        </div>
    <div>
      <Slider
          class="cover-process"
          :value="percent"
          @change="processChange"
          :dots="false"
          :trackStyle="{'background':'white'}"

      />
    </div>
      </div>
    </div>

    <div class="right-box">
      <div class="right-detial-box">
        <span class="card-box">
          <img src="/images/detail/card-icon.png" class="card-icon"/>
          小镇名片
        </span>
        <p class="card-content">
          {{townInfo.introduction }}
        </p>
      </div>
      <img :src="townInfo.card" class="qrcode"/>
    </div>
<!--    <VoiceSlider-->
<!--        v-if="volumeVisible"-->
<!--        @change="volumeChange"-->
<!--        @finish="toggleVolume"-->
<!--        :percent="volume"-->
<!--        class="slider"-->
<!--    />-->
  </div>
</template>

<script>
import {Slider} from 'ant-design-vue'
import {toFullVideo} from '@/utils/tools'

export default {
  name: 'player',
  components: {
    Slider,

  },
  props: {

    data:Object,
    townInfo:Object
  },
  data () {
    return {
      isRead: false,
      isPaused: true,
      visible: false,
      duration: 0,
      percent: 0,
      currentTime: 0,
      volumeVisible: true,
      volume: 50,
    }
  },
  computed: {
    video () {
      return this.$refs.video
    }
  },
  methods: {
    fixed (text) {
      let minute = parseInt(parseInt(text)/60);
      let second = parseInt(parseInt(text)%60);
      return `${minute<10?'0':''}${minute}:${second<10?'0':''}${second}`
    },
    play () {
      this.video.play()
    },
    loadedMetaData () {
      this.duration = this.video.duration
    },
    timeupdate () {
      this.currentTime = this.video.currentTime
      this.percent = this.video.currentTime * 100 / this.duration
    },
    processChange (value) {
      this.video.currentTime = value / 100 * this.duration
    },
    pause () {
      this.video.pause()
    },
    full () {
      toFullVideo(this.video)
    },
    toggleControls (visible) {
      this.visible = visible
    },

    initVideo () {
      // this.video = this.$refs.video
      // this.video.volume = this.volume / 100
      // this.video.addEventListener('playing', this.playing)
      // this.video.addEventListener('timeupdate', this.timeupdate)
      // this.video.addEventListener('ended', this.ended)
      // this.video.addEventListener('loadedmetadata', this.loadedMetaData)
    },
    volumeChange (value) {
      this.volume = value
      this.video.volume = value / 100
    },
    toggleVolume () {
      this.volumeVisible = !this.volumeVisible
    },
  },
  mounted () {
    this.initVideo()
  }
}
</script>

<style lang="scss" scoped>
.box {
  width: 12rem;
  height: 1.3rem;
  display: flex;
  flex-direction: row;
  box-shadow: 0 0.06rem 0.2rem rgba(0, 0, 0, 0.12);
  position: relative;
  .cover-img,

  {
    width: 2.67rem;
    height: 1.3rem;
  }
  .video{
    width: 2.67rem;
    height: 1.3rem;
    object-fit: cover;
  }


  .progress-box {
    width: 4.72rem;
    height: 100%;
    background: #01398D;
    padding: 0 0.17rem;

    display: flex;
    flex-direction: column;


    .pro-top-box {
      display: flex;
      align-items: center;
      margin-top: 0.2rem;

      .story-img {
        width: 0.19rem;
        height: 0.19rem;
        margin-right: 0.04rem;

      }

      .story-title {
        color: #ffffff;
        font-weight: 700;
        font-size: 0.18rem;
      }
    }
  }

  .right-box {
    background: #ffffff;
    display: flex;
    align-items: center;
    flex: 1;

    .right-detial-box {
      flex: 1;

      padding-left: 0.22rem;

      .card-box {
        color: #000000;
        font-size: 0.18rem;
        font-weight: 700;
        display: flex;
        align-items: center;

        .card-icon {
          width: 0.24rem;
          height: 0.2rem;
        }
      }

      .card-content {
        margin-top: 0.12rem;

        font-size: 0.14rem;
        height: 0.63rem;
        overflow-y: scroll;
        color: #666666;
      }
    }

    .qrcode {
      width: 1.03rem;
      height: 1.03rem;
      margin: 0 0.15rem;
    }
  }
}

.pro-bottom-box {
  display: flex;

  align-items: center;
  .time-text {
    color: #ffffff;
    font-size: 0.14rem;
    flex: 1;
  }

  .voice-img {
    width: 0.21rem;
    height: 0.17rem;
    margin-right: 0;
  }
}

.cover-btn {

  padding: 0.1rem 0.1rem 0.1rem 0;

  .btn-play {
    display: block;
    width: 0.14rem;
    height: 0.17rem;

  }

  .btn-pause {
    display: block;
    width: 0.14rem;
    height: 0.17rem;
  }

}

.cover-process {
  margin-top: 0;

}
.slider {
  position: absolute;
  //right: 0rem;
  left: 4.21rem+2.67rem;
  top: 0rem;
  width: 0.06rem;
  height: 1rem;
  background: yellowgreen;
}
/deep/.ant-slider-rail{
  background: #2F5B9D;
}
</style>

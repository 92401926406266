<template>
  <div class="bwrap">
    <swiper
        :allowTouchMove="false"
        :thumbs="{ swiper: swiperControl }"
        :navigation="{
        nextEl: '.next',
        prevEl: '.previous',
      }"
        @swiper="setSwiper"
        @slideChange="swiperChange"
    >
      <swiper-slide v-for="item in list" :key="item.id">
        <img class="cover" :src="item.picture" alt=""/>
      </swiper-slide>
    </swiper>
    <div class="control">

      <div class="bottom-center-box">
        <div class="left-arrow-box previous">
          <img
              :class="['btn-img', { 'btn-img-disabed': activeIndex === 0 }]"
              src="/images/common/arrow-left-w.png"
              alt=""
          />
        </div>
        <div style="width:11rem;">
          <swiper
              :slides-per-view="5"
              watch-slides-visibility
              watch-slides-progress
              @swiper="setSwiperControl"
          >
            <swiper-slide v-for="(item, index) in list" :key="item.id">
              <div class="control-item" :style="index==activeIndex?{'border':'1px solid #FF663F'}:{'border':'1px solid #ffffff'}">
                <img
                    :class="
                index === activeIndex ? 'item-cover-active' : 'item-cover'
              "
                    :src="item.picture"
                    alt=""
                />
              </div>
            </swiper-slide>
          </swiper>
        </div>

        <div class="left-arrow-box next">
          <img
              :class="[
          'btn-img',
          { 'btn-img-disabed': activeIndex + 1 === list.length },
        ]"
              src="/images/common/arrow-right-w.png"
              alt=""
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SwiperCore, {Controller, Thumbs, Navigation} from 'swiper'
import {Swiper, SwiperSlide} from 'swiper/vue'
import 'swiper/swiper.scss'

SwiperCore.use([Controller, Thumbs, Navigation])
export default {
  name: 'detail-album',
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      swiper: null,
      swiperControl: null,
      activeIndex: 0
    }
  },
  methods: {
    setSwiper (swiper) {
      this.swiper = swiper
    },
    setSwiperControl (swiper) {
      this.swiperControl = swiper
    },
    swiperChange (swiper) {
      this.activeIndex = swiper.activeIndex
    }
  }
}
</script>
<style lang="scss" scoped>
.bwrap {
  position: relative;
}

.cover {
  display: block;
  width: 100%;
  height: 8.37rem;
  object-fit: cover;
}

.control {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 1.78rem;
  background: rgba(0, 0, 0, 0.5);
  z-index: 2;
  padding: 0.17rem 0.11rem;
  box-sizing: border-box;
  justify-content: center;
  display: flex;

  .control-item {
    display: flex;
    justify-content: center;
    width:1.68rem;
    height: 1.24rem;
    box-sizing: border-box;

    .item-cover {
      text-align: center;
      width: 100%;
      height: 1.2rem;
      object-fit: cover;
      opacity: 0.3;
    }

    .item-cover-active {
      @extend .item-cover;
      opacity: 1;
    }
  }
}

.btn {
  cursor: pointer;

  .btn-img {
    display: block;
    width: 0.32rem;
    height: 0.63rem;
  }

  .btn-img-disabed {
    opacity: 0.2;
  }
}

.previous {

}

.next {

}

.bottom-center-box {

  width: 12rem;
  display: flex;
  align-items: center;

  .left-arrow-box {
    cursor: pointer;

    width: 0.5rem;
    height: 0.6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    .btn-img {
      display: block;
      width: 0.13rem;
      height: 0.26rem;

    }

    .btn-img-disabed {
      opacity: 0.2;
    }
  }

}
</style>

<template>
  <div class="box" >
    <img :src="data.picture" class="cover-img"/>

    <div class="progress-box">
      <div class="pro-top-box">
        <img src="/images/detail/story-icon.png" class="story-img">
        <p class="story-title">小镇故事</p>
      </div>
      <div>
        <div class="pro-bottom-box">
          <div class="cover-btn"  @selectstart="forbid" :draggable="false">
            <img
                @click="play"
                v-if="!isPlay"
                class="btn-play"
                src="/images/detail/play.png"
                alt=""
            />
            <img
                v-else
                @click="pause"
                class="btn-pause"
                src="@/assets/images/audio/pause.png"
                alt=""
            />
          </div>
          <span class="time-text">
             {{ fixed(currentTime) }}/{{ fixed(duration) }}
            </span>
<!--          <div @click="toggleVolume">-->
<!--            <img src="/images/detail/voice.png" class="voice-img">-->
<!--          </div>-->
        </div>
        <div>
          <Slider
              class="cover-process"
              :value="percent"
              @change="volumeChange"
              @finish="toggleVolume"
              :trackStyle="{'background':'white'}"
          />

        </div>
      </div>
    </div>

    <div class="right-box">
      <div class="right-detial-box">
        <span class="card-box">
          <img src="/images/detail/card-icon.png" class="card-icon"/>
          小镇名片
        </span>
        <p class="card-content">
          {{townInfo.introduction }}
        </p>
      </div>
      <img :src="townInfo.card" class="qrcode"/>
    </div>

  </div>
</template>

<script>
import {Slider} from 'ant-design-vue'
import {toFullVideo} from '@/utils/tools'
// import VoiceSlider from '@/components/common/video/video'
export default {
  name: 'player',
  components: {
    Slider,
  },
  props: {
    data:Object,
    townInfo:Object
  },
  data () {
    return {
      visible: false,
      audio: null,
      isPlay: false,
      percent: 0,
      volume: 50,
      duration: 0,
      currentTime: 0
    }
  },
  methods: {
    fixed (text) {
      let minute = parseInt(parseInt(text)/60);
      let second = parseInt(parseInt(text)%60);
      return `${minute<10?'0':''}${minute}:${second<10?'0':''}${second}`
    },
    forbid (e) {
      e.preventDefault()
    },
    play () {
      this.audio.play()
    },
    pause () {
      this.audio.pause()
      this.isPlay = false
    },
    playing () {
      this.isPlay = true
    },
    timeupdate () {
      this.currentTime = this.audio.currentTime
      this.percent = this.currentTime * 100 / this.duration
    },
    ended () {
      this.isPlay = false
      this.percent = 0
      this.currentTime = 0
    },
    loadedMetaData () {
      this.duration = this.audio.duration
    },
    volumeChange (value) {
      this.volume = value
      this.audio.volume = value / 100
    },
    toggleVolume () {
      this.visible = !this.visible
    },
    initAudio () {
      this.audio = document.createElement('audio')
      this.audio.src = this.data.video
      this.audio.volume = this.volume / 100
      this.audio.addEventListener('playing', this.playing)
      this.audio.addEventListener('timeupdate', this.timeupdate)
      this.audio.addEventListener('ended', this.ended)
      this.audio.addEventListener('loadedmetadata', this.loadedMetaData)
    }
  },
  mounted () {
    this.initAudio()
  }
}
</script>

<style lang="scss" scoped>
.box {
  width: 12rem;
  height: 1.3rem;
  display: flex;
  flex-direction: row;
  box-shadow: 0 0.06rem 0.2rem rgba(0, 0, 0, 0.12);
  position: relative;
  .cover-img {
    width: 2.67rem;
    height: 100%;
  }

  .progress-box {
    width: 4.72rem;
    height: 100%;
    background: #01398D;
    padding: 0 0.17rem;

    display: flex;
    flex-direction: column;


    .pro-top-box {
      display: flex;
      align-items: center;
      margin-top: 0.2rem;

      .story-img {
        width: 0.19rem;
        height: 0.19rem;
        margin-right: 0.04rem;

      }

      .story-title {
        color: #ffffff;
        font-weight: 700;
        font-size: 0.18rem;
      }
    }
  }

  .right-box {
    background: #ffffff;
    display: flex;
    align-items: center;
    flex: 1;

    .right-detial-box {
      flex: 1;

      padding-left: 0.22rem;

      .card-box {
        color: #000000;
        font-size: 0.18rem;
        font-weight: 700;
        display: flex;
        align-items: center;

        .card-icon {
          width: 0.24rem;
          height: 0.2rem;
        }
      }

      .card-content {
        margin-top: 0.12rem;

        font-size: 0.14rem;
        height: 0.63rem;
        overflow-y: scroll;
        color: #666666;
      }
    }

    .qrcode {
      width: 1.03rem;
      height: 1.03rem;
      margin: 0 0.15rem;
    }
  }
}

.pro-bottom-box {
  display: flex;

  align-items: center;
  .time-text {
    color: #ffffff;
    font-size: 0.14rem;
    flex: 1;
  }

  .voice-img {
    width: 0.21rem;
    height: 0.17rem;
    margin-right: 0;
  }
}

.cover-btn {

  padding: 0.1rem 0.1rem 0.1rem 0;

  .btn-play {
    display: block;
    width: 0.14rem;
    height: 0.17rem;

  }

  .btn-pause {
    display: block;
    width: 0.14rem;
    height: 0.17rem;
  }

}

.cover-process {
  margin-top: 0;

}
.slider {
  position: absolute;
  //right: 0rem;
  left: 4.21rem+2.67rem;
  top: 0rem;
  width: 0.06rem;
  height: 1rem;
  background: yellowgreen;
}
/deep/.ant-slider-rail{
  background: #2F5B9D;
}
</style>

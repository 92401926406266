<template>
  <div class="wrap">
    <div class="header">
      <img :src="townsInfo.picture" class="headerImg"/>
    </div>
    <div v-if="storyData.length>0" style="margin-top: -0.65rem">
      <Player
          :data="storyData[0]"
          :townInfo="townsInfo"
          v-if="$fileType.isVideo(storyData[0].video)"
      />
      <AudioPlay
          :data="storyData[0]"
          :townInfo="townsInfo"
          v-else/>
    </div>

    <div class="center-box">
      <section>
        <div class="content-tools">
          <h3 class="tools-title">小镇动态</h3>
          <h4 class="tools-more" @click="goPage(`/industry${this.$route.query?.hasOwnProperty('tn')?`?tn=${this.$route.query?.tn}`:''}${this.$route.query?.hasOwnProperty('tid')?`&tid=${this.$route.query?.tid}`:''}`)">
            了解更多
            <img src="/images/detail/more.png" class="tools-more-icon"/>
          </h4>
        </div>
        <div class="news-box">
          <div class="news-left-box">
            <BannerSwiper :list="hotData" v-if="hotData.length>0"/>
            <Default v-else :width="6.6" :height="4.15" :imgWidth="1.13" :imgHeight="1.13" :prompt="'建设中...'"/>
          </div>
          <div class="news-right-box">
            <a-tabs :tabBarStyle="tabBarStyle" centered>
              <a-tab-pane v-for="item in tabsData" :key="item.key" :tab="item.tab">
                <TownList :list="item.datas"/>
              </a-tab-pane>
            </a-tabs>
          </div>
        </div>

      </section>

      <section style="margin-top: 0.6rem">

        <div class="content-tools">
          <h3 class="tools-title">小镇VR</h3>

        </div>
        <div v-if="Object.keys(vrData).length>0">
          <div class="vr-box" @click="goPage(vrData.link)">
            <img :src="vrData.picture" class="vr-img"/>
            <span class="vr-cover-box">
            <img src="/images/detail/vricon.png" class="vr-cover-img">
          </span>
          </div>
        </div>
        <Default v-else :width="12" :height="4.5" :imgWidth="1.13" :imgHeight="1.13" :prompt="'建设中...'"/>
      </section>


      <section >
        <div class="content-tools">
          <h3 class="tools-title">小镇产业</h3>
          <h4 class="tools-more" @click="goPage(`/industry${this.$route.query?.hasOwnProperty('tn')?`?tn=${this.$route.query?.tn}`:''}${this.$route.query?.hasOwnProperty('tid')?`&tid=${this.$route.query?.tid}`:''}`)">
            了解更多
            <img src="/images/detail/more.png" class="tools-more-icon"/>
          </h4>
        </div>
        <InsdustryItem :data="productData"/>
      </section>
    </div>

    <section style="margin-top: 0.6rem" v-if="list.length>0">
      <div class="album-box">
        <Album :list="list"/>
      </div>
    </section>
    <div class="center-box">
      <section >
        <div class="content-tools">
          <h3 class="tools-title">小镇访谈录</h3>
<!--          <h4 class="tools-more" @click="goPage(`/industry?id=${id}`)">-->
<!--            了解更多-->
<!--            <img src="/images/detail/more.png" class="tools-more-icon"/>-->
<!--          </h4>-->
        </div>

        <div class="interview-box" v-if="interviewData.length>0"
        @click="goPage(`interviewdetail${this.$route.query?.hasOwnProperty('tn')?`?tn=${this.$route.query?.tn}`:''}${this.$route.query?.hasOwnProperty('tid')?`&tid=${this.$route.query?.tid}`:''}`)"
        >
          <img :src="interviewData[0].picture" class="interview-img"/>
          <div class="interview-cover-box">
            <div class="interview-btn">
              点击进入详情
            </div>
          </div>
        </div>
        <Default v-else :width="12" :height="5.77" :imgWidth="1.13" :imgHeight="1.13" :prompt="'建设中...'"/>
      </section>
    </div>

  </div>
</template>

<script>
import Player from '@/components/detail/towndetail/video-player'
import AudioPlay from '@/components/detail/towndetail/audio-player'
import BannerSwiper from '@/components/detail/towndetail/banner-swiper'
import InsdustryItem from '@/components/detail/towndetail/industry-item'
import Album from '@/components/detail/towndetail/album'
import {reactive, ref} from 'vue'
import api from '@/api'
import {useRoute} from 'vue-router'
import TownList from '@/components/home/towns-list'
import Default from '@/components/common/default'


export default {
  name: 'towndetail',
  components: {
    Player,
    BannerSwiper,
    InsdustryItem,
    Album,
    AudioPlay,
    TownList,
    Default
  },
  methods: {
    goPage (url) {
      if (url && url.includes('http') || url?.includes('www')) {
        window.open(url, '_target')
      } else {
        this.$router.push(url)
      }

    },
  },
  setup () {
    const router = useRoute()
    const townId = router.query.tid
    const tabBarStyle = reactive({color: '#A8AEB6'})
    const tabsData = ref([
      {
        key: 'TOWN_DYNAMIC',
        tab: '小镇动态',
        datas: [],
      }, {
        key: 'POLICY_DOCUMENT',
        tab: '政策文件',
        datas: [],
      }, {
        key: 'INVESTMENT_INFORMATION',
        tab: '招商信息',
        datas: [],
      },
    ])
    const hotData = ref([])
    const vrData = ref({})
    const list = ref([])
    const interviewData = ref([])
    const productData = ref([])
    const storyData = ref([])
    const townsInfo = ref({})

    const getData = () => {
      const gethotData = async () => {
        let res = await api.hotspot({townId: townId})
        hotData.value = res || []
      }

      const getVrData = async () => {
        let res = await api.townVr({townId: townId, pageNum: 1, pageSize: 1})
        if (res.records.length > 0) {
          vrData.value = res.records[0]
        } else {
          vrData.value = {}
        }
      }
      const getAblum = async () => {
        let res = await api.townAblum({townId: townId, pageNum: 1, pageSize: 10})
        console.log(res)
        list.value = res || []
      }

      const getInterview = async () => {
        let res = await api.townStory({townId: townId, pageNum: 1, pageSize: 1, tabs: 'TALK'})
        interviewData.value = res.records || []
      }

      const getProduct = async () => {
        let res = await api.townProduct({townId: townId, pageNum: 1, pageSize: 5, tabs: 'INDUSTRY'})
        return productData.value = res.records || []
      }

      const getStory = async () => {
        let res = await api.townStory({townId: townId, pageNum: 1, pageSize: 1, tabs: 'STORY'})
        storyData.value = res.records || []
      }
      const getTownInfo = async () => {
        let res = await api.getTown({id: townId})
        townsInfo.value = res
      }


      getStory()
      gethotData()
      getVrData()
      getAblum()
      getInterview()
      getProduct()
      getTownInfo()

    }
    // 循环增加空对象
    const forItem = (field, total, length) => {
      for (let i = 0; i < total - length; i++) {
        field.push({})
      }
    }
    let length = 0
    // 小镇动态右侧数据请求
    let params = {
      tabs: 'TREND',
      pageNum: 1,
      pageSize: 3
    }
    tabsData.value.map(async item => {
      params.townDynamicTypeEnum = item.key
      params.townId = townId
      let res = await api.townProduct(params)
      item.datas = res?.records ?? []
      length = item.datas.length
      forItem(item.datas, 3, length)
    })

    getData()

    return {
      list,
      hotData,
      vrData,
      interviewData,
      productData,
      storyData,
      townsInfo,
      tabBarStyle,
      tabsData
    }
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ffffff;
  padding-bottom: 0.8rem;

}

.header {
  //background: url("/images/detail/banner.png") no-repeat;
  width: 100%;
  height: 7.36rem;
  background-size: 100% 100%;

  .headerImg {
    width: 100%;
    height: 7.36rem;
  }
}

.center-box {
  width: 12rem;

  .content-tools {
    display: flex;
    justify-content: space-between;
    margin-top: 0.6rem;
    margin-bottom: 0.3rem;

    .tools-title {
      font-size: 0.3rem;
      font-weight: 500;
      color: #000000;

    }

    .tools-more {
      font-size: 0.2rem;
      color: #666666;
      position: relative;

      cursor: pointer;
      font-weight: 400;
      align-items: center;
      justify-content: center;
      display: flex;
    }

    .tools-more-icon {
      width: 0.09rem;
      height: 0.18rem;
      margin-left: 0.05rem;
    }
  }

  .news-box {
    display: flex;

    .news-left-box {

      width: 6.6rem;
      height: 4.15rem;
    }

    .news-right-box {
      width: 5.23rem;
      padding: 0 0.17rem;

      /deep/ .ant-tabs-tab {
        width: calc(4.2rem / 3) !important;
        padding: 0 0 !important;
        font-size: .24rem !important;
        font-weight: 500 !important;
        margin-bottom: .12rem !important;
      }
    }
  }

  .vr-img,
  .vr-box {
    width: 100%;
    height: 4.5rem;
    display: flex;

    .vr-cover-box {
      display: flex;
      width: 12rem;
      height: 4.5rem;
      position: absolute;
      justify-content: center;
      align-items: center;

      .vr-cover-img {
        width: 0.88rem;
        height: 1.17rem;
      }
    }
  }
}

.album-box {
  width: 19.20rem;
  //width: 12rem;
  height: 8.37rem;
  background: darkslategray;
}

.interview-box {
  width: 100%;
  height: 5.77rem;
  position: relative;
  margin-bottom: 0.6rem;

  .interview-img {
    width: 100%;
    height: 5.77rem;
  }

  .interview-cover-box {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 5.77rem;
    cursor: pointer;
    top: 0;

    .interview-btn {

      background: #01398D;
      position: absolute;
      color: #ffffff;
      opacity: 0.8;
      font-size: 0.18rem;
      padding: 0.12rem 0.24rem;

    }
  }

}


/deep/ .ant-tabs-tab-active {
  color: #01398D;
}

/deep/ .ant-tabs-tab {
  //height: 0.37rem;

  &:hover {
    color: #01398D;
  }
}


/deep/ .ant-tabs-ink-bar {
  background: #01398D;
}


</style>

<template>
  <div class="insdustry-box">
    <div class="left-box" @click="goPage(data[0])" v-if="data.length>0">
      <img :src="data[0].picture" class="first-img">
      <div class="left-bottom-box" >
        <p class="left-title">
          {{data[0].title }}
        </p>
        <p class="left-date">
          {{$date.moment(data[0].createDate,'YYYY-MM-DD')}}
        </p>
      </div>
    </div>
    <Default v-else :width="4.96" :height="6.34" :imgWidth="1.13" :imgHeight="1.13" :prompt="'建设中...'"/>

    <div class="right-box">
      <div class="right-item-box" v-for="(item,index) in 4" :key="index">
        <div v-if="data.length>0 && data.length-1>index" @click="goPage(data[index+1])">
          <img :src="data[index+1]?.picture" class="right-item-img">
          <div class="left-title-box">
            <p class="left-title">{{data[index+1]?.title}}</p>
          </div>
        </div>
        <Default v-else :width="3.04" :height="3.04" :imgWidth="1.13" :imgHeight="1.13" :prompt="'建设中...'"/>
      </div>
    </div>
  </div>
</template>

<script>
import Default from '@/components/common/default'
export default {
  name: 'industry-item',
  components:{
    Default
  },
  props: {
    data: {
      type: Array,
      default: () => []
    }
  },
  methods:{
    goPage(d){
      this.$router.push(`/articledetail?pt=0&id=${d.id}${this.$route.query.tn?`&tn=${this.$route.query.tn}`:''}${this.$route.query.tid?`&tid=${this.$route.query.tid}`:''}`)
    }
  }

}
</script>

<style lang="scss" scoped>
.insdustry-box {
  display: flex;


  .left-box {
    width: 5.34rem;
    cursor: pointer;
    height: 3.04rem * 2 + 0.26rem;


    .first-img {
      width: 100%;
      height: 5.34rem;
    }

    .left-bottom-box {
      padding: 0 0.17rem;
      flex: 1;
      border: 1px solid #999999;
      border-radius: 0.03rem;


      .left-title {
        color: #333333;
        font-size: 0.16rem;
        padding: 0.13rem 0;
        @include text-moreLine(1)
      }

      .left-desc {
        color: #666666;
        line-height: 0.18rem;
        font-size: 0.14rem;
        @include text-moreLine(4)
      }

      .left-date {
        margin: 0.11rem 0;
        color: #999999;
        font-size: 0.12rem;
      }
    }

  }

  .right-box {
    display: flex;
    flex-wrap: wrap;
    margin-left: 0.29rem;
    flex: 1;

    .right-item-box {
      width: 3.04rem;
      height: 3.04rem;
      position: relative;

      &:not(:nth-of-type(2n)) {
        margin-right: 0.16rem;
        margin-bottom: 0.26rem;
      }

      .left-title-box {
        position: absolute;
        left: 0;
        width: 3.04rem;
        height: 0.5rem;
        bottom: 0;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.89) -27.91%, rgba(0, 0, 0, 0.147153) 75.53%, rgba(0, 0, 0, 0) 116.28%);
        display: flex;
        align-items: center;
        padding: 0 0.11rem;

        .left-title {
          color: #ffffff;
          font-size: 0.16rem;
          @include text-moreLine(1)
        }

      }

      .right-item-img {
        width: 3.04rem;
        height: 3.04rem;
      }
    }
  }

}

</style>
